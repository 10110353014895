import axios from "axios"
import { useEffect, useState } from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import path from "../path"
import { toast } from "react-toastify"

function EditReceipts({receiptVoucher,loginOk})
{
    /// for testing only
        const selectedOwnerId='0'
        const SelectInsurance='abcd'
        const selectedInsuranceId='2'
        const SelectInsuranceOwner='bbb'
        //alert(receiptVoucher.id)
        // <td>{receiptVoucher.id}</td>
        // <td>{receiptVoucher.number}</td>
        // <td>{receiptVoucher.owner_name}</td>
        // <td>{receiptVoucher.who_pay}</td>
        // <td>{receiptVoucher.amont}</td>
        // <td>{receiptVoucher.note}</td>
        // <td>{receiptVoucher.date}</td>

    ///
   
    const emptyForm ={
        id:'',
        insurance_number:'',
        voucher_date:'',
        amount:'',
        that_for:'1',
        who_pay:'',
        note:''
      }
    const [postData,setPostData] = useState(emptyForm)
      useEffect(()=>{
        setPostData({
            id:receiptVoucher.id,
            insurance_number:receiptVoucher.number,
            voucher_date:receiptVoucher.date,
            amount:receiptVoucher.amont,
            that_for:'1',
            who_pay:receiptVoucher.who_pay,
            note:receiptVoucher.note
        })
      },[receiptVoucher])
      //const ownerRef = useRef();
      //const [selectOwnerData,setSelectOwnerData]=useState([])
    

    const handelOnChange = (e) => { 
        const value = e.target.value
        const name = e.target.name
        setPostData({...postData, [name]: value})
        //console.log("postData");
        //console.log(postData)
    }
    
    const updatePost= (postname,postval)=>
        {
          setPostData({...postData, [postname]: postval})
          //console.log(postData)
        }
        const handelSubmit = e => {
            e.preventDefault()
            console.log(postData);
            // if(postData.number==='' ||
          
            // postData.car_number==='' ||
            // postData.start_date==='' ||
            // postData.end_date==='' ||
            // postData.next_date==='' ||
            // postData.value==='' ||
            // postData.real_value==='' ||
            // postData.owner==='' )
            // {
            //   alert("u must add all feilds")
            //   return
            }
            axios.post(path+'edit.php?do=receiptVoucher',postData)
              .then(res => {
                console.log("Add receiptVoucher res="+res);
          
                if(res.data.result===1)
                {
                    //alert("تمت الاضافة")
                    //setTableData(res.data.data) ;
                    toast.success("تم الالتعديل بنجاج !", {
                      position: toast.POSITION.TOP_CENTER
                    });
                    //setPostData(emptyForm)
                }
                
          
              })
              .catch((error) => {
                console.log('error');
                console.log(error);
              })
              
    



    return(<div className="container">
    <Form onSubmit={handelSubmit}>
      <Row className="mb-3">
      <Form.Group as={Col} className="mb-3" >
              <Form.Label>تاريخ السند</Form.Label>
              <Form.Control 
                name="voucher_date"
                value={postData.start_date}
                onChange={handelOnChange}
                type="date" 
                placeholder="اختر التاريخ" />
      </Form.Group>
      <Form.Group  as={Col} className="mb-3">
          <Form.Label>المبلغ نقدي</Form.Label>
          <Form.Control 
            type="number" 
            name ="amount"
            value={postData.amount} 
            onChange={handelOnChange}
            placeholder="ادخل المبلغ" />
        </Form.Group>
        <Col>
            <SelectInsurance updatePost={updatePost} defVal={selectedInsuranceId} />
        </Col>
        <Col>
          <SelectInsuranceOwner updatePost={updatePost} lable="احتر اسم الدافع" name="who_pay" defVal={selectedOwnerId} />
          {/* <Form.Group className="mb-3" >
            <Form.Label>اختر الدافع</Form.Label>
            <FormTypeahead
            data={ownersList}  
            updatePost={updatePost}
            name="who_pay" 
            labelKey="name"
            selectedval={selectOwnerData}
            ref={ownerRef}/>
            <AddCarOwner autoSelectOwner={autoSelectOwner}/>
          </Form.Group> */}
        </Col>
        
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} className="mb-3">
          <Form.Label>ملاحظات</Form.Label>
          <Form.Control 
            name="note"
            value={postData.note}
            onChange={handelOnChange} 
            type="text" 
            placeholder="ملاحظات ..." />
        </Form.Group>
      </Row>
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
    </div>)   
}
export default EditReceipts
