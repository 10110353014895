
import { BrowserView, MobileView} from 'react-device-detect'
import { NavLink } from 'react-router-dom';
import "./Navbar.css"

function Navbar({loginOk})
{
    return(<>
        <BrowserView>
        <nav className="navbar navbar-expand-lg navbar-light bg-light x150size">
          <div className="container-fluid">
  
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav">
                <li className="nav-item">
                  <NavLink to='/' className="nav-link active" aria-current="page" >الرأيسية</NavLink>
                </li>
                
                { !loginOk? <li className="nav-item">
                  <NavLink to='/login' className="nav-link" >تسجيل الدخول</NavLink>
                </li> : <><li className="nav-item">
                  <NavLink to='/insurances' className="nav-link" aria-current="page" >جدول التامينات</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to='/cars' className="nav-link" >قائمة السيارات</NavLink>
                </li>
                {/* <li className="nav-item">
                  <NavLink to='/users' className="nav-link" >المستخدمين</NavLink>
                </li> */}
                <li className="nav-item">
                  <NavLink to='/receipts' className="nav-link" >المقبوضات</NavLink>
                </li>
                {/* <li className="nav-item">
                  <NavLink to='/useraccont' className="nav-link" >كشف حساب زبون</NavLink>
                </li> */}
                
                <li className="nav-item">
                  <NavLink to='/logout' className="nav-link" >تسجيل الخروج</NavLink>
                </li></>}
              </ul>
            </div>
          </div>
        </nav>
      </BrowserView>
      <MobileView>
      <ul className="navbar-nav">
        <li className="nav-item">
          <NavLink to='/' className="nav-link active" aria-current="page" >الرأيسية</NavLink>
        </li>
        
        { !loginOk? <li className="nav-item">
          <NavLink to='/login' className="nav-link" >تسجيل الدخول</NavLink>
        </li> :<>
        <li className="nav-item">
          <NavLink to='/insurances' className="nav-link" aria-current="page" >جدول التامينات</NavLink>
        </li>
        <li className="nav-item">
          <NavLink to='/cars' className="nav-link" >قائمة السيارات</NavLink>
        </li>
        {/* <li className="nav-item">
          <NavLink to='/users' className="nav-link" >المستخدمين</NavLink>
        </li>
        <li className="nav-item">
          <NavLink to='/useraccont' className="nav-link" >كشف حساب زبون</NavLink>
        </li> */}
        <li className="nav-item">
          <NavLink to='/logout' className="nav-link" >تسجيل الخروج</NavLink>
        </li></>}
        
        
      
      </ul>
      </MobileView></>
    )
}
export default Navbar;