import axios from "axios"
import { useState } from "react"

import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Col, Form, Row } from "react-bootstrap";
import SelectInsuranceOwner from "./fourmcomponants/SelectInsuranceOwner";
import SelectCarModel from "./fourmcomponants/SelectCarModel";
import path from "../path"
//import SelectInsuranceNotLinked from "./fourmcomponants/SelectInsuranceNotLinked";




function AddCar({updateTable})

{
    //const path='https://dev.ps-on-line.com/api/'

    const updatePost= (postname,postval)=>
    {
      setPostData({...postData, [postname]: postval})
      //console.log(postData)
    }
    
    const emptyPostData={
        car_number:'',
        note:'',
        owner:'',
        car_model:'',
        insurance:''
    }
   
    
    const [postData,setPostData]=useState(emptyPostData)
 

    
    const handelOnChange = (e) => {
        const value = e.target.value
        const name = e.target.name
        setPostData({...postData, [name]: value})
        console.log(postData)
    }
    const handelSubmit = e => {
        e.preventDefault()
        console.log(postData);
        axios.post(path+'add.php?do=car',postData)
          .then(res => {
            //console.log("addcar res="+res);

            if(res.data.result===1)
            {

                //setCarsList(res.data.data)
                
                toast.success("تمت الاضافة بنجاج!",{
                    position: toast.POSITION.TOP_CENTER
                  });
                setPostData(emptyPostData)
                // const timeout = setTimeout(() => {
                //     // 👇️ redirects to an external URL
                //     window.location.reload();
                //   }, 3000);
              
                //   return () => clearTimeout(timeout);
                updateTable()
                document.getElementById("uncontrolled-tab-cars-tab-table").click();
                
            }
            //
        
        else if ((res.data.result === -1))
        {
            if (res.data.massage==="23000")
            {
                toast.error("رقم السيارة مكرر",{
                    position: toast.POSITION.TOP_CENTER
                  })
                  
                return
            }
            else
            {
                alert(res.data.massage)
                console.log(res.data.data);
            }
        }
        else
        {
            alert("خطأ غير معروف")
        }
            
    
          })
          .catch((error) => {
            console.log('error');
            console.log(error);
          })
          
          
    
      }

    return (<div><h2>
        اضافة سيارة
    </h2>
    
    <Form onSubmit={handelSubmit} >
      <Row className="mb-3">
        <Form.Group  as={Col} className="mb-3">
            <Form.Label>رقم السيارة </Form.Label>
            <Form.Control 
                type="text" 
                name ="car_number"
                value={postData.car_number} 
                onChange={handelOnChange}
                placeholder="ادخل رقم السيارة " />
        </Form.Group>
        <Col>
          <SelectCarModel updatePost={updatePost} />
        </Col>
        <Col>
          <SelectInsuranceOwner updatePost={updatePost} lable="احتر مالك السيارة"/>
        </Col>
        {/* <Col>
          <SelectInsuranceNotLinked updatePost={updatePost} />
        </Col> */}
      </Row>
      <Row>
      <Form.Group  as={Col} className="mb-3">
            <Form.Label>ملاحظات</Form.Label>
            <Form.Control 
                type="text" 
                name ="note"
                value={postData.note} 
                onChange={handelOnChange}
                placeholder="ملاحظات" />
        </Form.Group>
        </Row>
      <Button variant="primary" type="submit">
      اضف سيارة
      </Button>
    </Form>

    </div>)
}
export default AddCar