
import { useEffect, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './navtap/Home';
import Insurances from './navtap/Insurances';
import Login from './navtap/Login';
import Users from './navtap/Users';
import Logout from './navtap/Logout';
import Cars from './navtap/Cars';
//import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.rtl.min.css'
import Navbar from './componant/Navbar';
import { ThemeProvider } from 'react-bootstrap';
import CarInfo from './navtap/CarInfo';
import { ToastContainer } from 'react-toastify';
import InsuranceInfo from './navtap/InsuranceInfo';
import Receipts from './navtap/Receipts';
import UserAccont from './navtap/UserAccont.js';
import jwtDecode from 'jwt-decode';
import axios from 'axios';
import ReceiptsInfo from './navtap/ReceiptsInfo';



//const About = ()=>(<h1>Users</h1>)
function App() {
  // may use later 
  //const [username,setUsername]=useState('');
  //const [id,setId]=useState('');
  
  // "3Ig^-$*fe;p?"

  const [fullName,setFullName]=useState('Gust user');
  const [loginOk,setLoginOk]=useState(false)
  
  
  //console.log(auth);
  useEffect(()=>{
    
    const auth=localStorage.getItem('auth')
    
    if (auth) {
      try {
      const user = jwtDecode(auth)
      console.log(user);
      setFullName(user.fullname)
      setLoginOk(true)
      axios.defaults.headers.common['Authorization']=auth
      }
      catch (e) {
        localStorage.clear()
      }
    }

  },[])

  
  return (
    <ThemeProvider>
    <BrowserRouter>
      <h4>اهلا بك يا {fullName}</h4>
      <Navbar loginOk={loginOk}/>
      <Routes>
        <Route path='/home' element={<Home />} />
        <Route path='/insurances' element={<Insurances loginOk={loginOk} />} />
        <Route path='/insurances/:id' element={<InsuranceInfo  loginOk={loginOk} />} />
        <Route path='/cars' element={<Cars loginOk={loginOk} />} />
        <Route path='/cars/:id' element={<CarInfo  loginOk={loginOk} />} />
        <Route path='/users/:id' element={<Users  loginOk={loginOk} />} />
        <Route path='/users' element={<Users  loginOk={loginOk} />} />
        <Route path='/receipts' element={<Receipts  loginOk={loginOk} />} />
        <Route path='/receipts/:id' element={<ReceiptsInfo  loginOk={loginOk} />} />
        <Route path='/' element={<Home  loginOk={loginOk} />} />
        <Route path='/login' element={<Login />} />
        <Route path='/logout' element={<Logout />} />
        <Route path='/useraccont' element={<UserAccont  loginOk={loginOk} />} />
        <Route path='*' element={<Home  loginOk={loginOk} />} />


      </Routes>

    </BrowserRouter><ToastContainer/>
    </ThemeProvider>
    
  );
}
export default App;
