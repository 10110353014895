import axios from 'axios';
import React, { useState } from 'react';
import { CloseButton } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import path from "../path"

function AddCarModel({autoSelectModel}) {
  //const path='https://dev.ps-on-line.com/api/'
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const [postData,setPostData] = useState({model:'', manufacture:''})
  const handelOnChange = (e) => {
    const value = e.target.value
    const name = e.target.name
    setPostData({...postData, [name]: value})
    console.log(postData)
  }
  const handelSubmit = e => {
    e.preventDefault()
    console.log(postData);
    axios.post(path+'add.php?do=model',postData)
          .then(res => {
            //console.log("addcar res="+res);

            if(res.data.result===1)
            {

                //setCarsList
                //setSelectModelData(res.data.data)
                setShow(false)
                const data=res.data.data
                for(var i = 0; i < data.length; i++)
                {
                  if(data[i].name === postData.name)
                  {
                    //alert(data[i].id) 
                    autoSelectModel(data,data[i])
                  }
                }
            }
            else
            {
              alert("not found");
            }
            
            
    
          })
          .catch((error) => {
            console.log('error');
            console.log(error);
          })
    
      }
  
  return (
    <>
      <Button variant="primary" onClick={handleShow}>
        +
      </Button>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title><CloseButton onClick={handleClose} />اضفة موديل لسيارة</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>الموديل</Form.Label>
              <Form.Control
                value={postData.model}
                onChange={handelOnChange}
                type="text"
                name="model"
                placeholder="CORSA"
                autoFocus
              />
            </Form.Group>
            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>الشركة المصنعة</Form.Label>
              <Form.Control type="text"
              name="manufacture"
              value={postData.manufacture}
              onChange={handelOnChange}
                placeholder="OPEL"/>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            الغاء
          </Button>
          <Button variant="primary" onClick={handelSubmit}>
            اضافة
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
export default AddCarModel