import { Tab, Tabs } from "react-bootstrap"
import ReceiptVoucher from "../componant/ReceiptVoucher"
import ReceiptVoucherTable from "../componant/ReceiptVoucherTable"
import { useRef } from "react";
import { useSearchParams } from "react-router-dom";

function Receipts()
{
  const ref = useRef();
  const updateTable=()=>{
    ref.current?.updateTable()
  }
  const [searchParams] = useSearchParams();
  const tab=(searchParams.get('tab'))?searchParams.get('tab'):'table'
  return(<Tabs defaultActiveKey={tab}
    id="uncontrolled-tab-receipts" 
    className="mb-3">
      <Tab eventKey="table" title="قائمة المقبوضات">
       <ReceiptVoucherTable ref={ref}/>
      </Tab>
      <Tab eventKey="add" title="سند قبض">
        <ReceiptVoucher updateTable={updateTable} />
      </Tab>
      
  </Tabs>)
}
export default Receipts