
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Link } from 'react-router-dom';
import DeleteInsurance from '../delete/DeleteInsurance';



function InsuranceListActions({insurance,removeTableItem}) {
  
  const l='/insurances/'+insurance.id
  const receiptsLink = '/receipts/?tab=add&&insurance='+insurance.id+'&owner='+insurance.insurances_owner_id // tab=add&&car=8
  return (<DropdownButton insurance={insurance}  title="المزيد">
  {/* <Dropdown.Item href="#/action-1">انشاء تامين للسيارة</Dropdown.Item> */}
  <Dropdown.Item as={Link} to={l}> تعديل </Dropdown.Item>
  <Dropdown.Item ><DeleteInsurance insurance={insurance} removeTableItem={removeTableItem} /></Dropdown.Item>
  <Dropdown.Item as={Link} to={receiptsLink}> سند قبض </Dropdown.Item>
</DropdownButton>)
}

export default InsuranceListActions;