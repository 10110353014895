


//import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
function Home() {
    console.log("home");
    return (<div className="container">
      <h1>برنامج إدارة التامين</h1>
      <br />
      <div class="container">
      
      <ul>
        <li><h3>لا يمكن الاستفادة من البرنامج دون تسجيل الدخول باسم مستخدم وكله مرور</h3></li>
      </ul>
      <h2>طريقه اضفه سيارة</h2>
      <ul>
        <li><h4>اذهب أولا قائمة السيارات/ إضافة سيارة : ادخل رقم السيارة وتراز السيارة (في غير موجود بالقائمة يمكنك أضافته من خلال زر “+” ) و مالك السيارة(في حال غير موجود بالقائمة يمكنك إضافة من خلال اضف شخص جديد )</h4></li>
      </ul>
      <h2>طريقه اضفه تامين</h2>
      <ul>
        <li><h4>قبل إضافة التامين يجب إضافة السيارة المراد إنشاء تامين لها</h4></li>
        <li><h4>اذهب لقائمة جدول التأمينات/ أضافه تامين  وادخل البيانات  المطلوبة ثم أصافة تامين</h4></li>
        <li><h4>أو من "قائمة السيارات"  بجانب السيارة المراد إنشاء تامين لها اختر المزيد / إنشاء تامين للسيارة وادخل بيانات التامين </h4></li>
        <li><h4>لا يمكن اضافه تامين لاكثر من سيارة ولا يمكن عمل تامين دون ادخال سيارة</h4></li>
      </ul>
    </div>
    </div>
    );
  }
  export default Home