import { useParams } from "react-router-dom"
import EditReceipts from "../componant/EditReceipts"
import path from "../path"
import axios from "axios"
import { useEffect, useState } from "react"


function ReceiptsInfo({loginOk})
{
const { id } = useParams()
const [receiptVoucher,setReceiptVoucher]=useState({})
const [idOk,setIdOk]=useState(!isNaN(id) && id>0)
useEffect(()=>{

    if(!idOk) return
    axios.post(path+'insurances.php?do=one_receiptVoucher',{'id':Number(id)})
      .then(res=>{
        console.log(res);
        if(res.data.result===1)
        {
            
            const rows=res.data.data
            if (rows.length>0)
            {
                setReceiptVoucher(rows[0])
                setIdOk(true)
                //alert("id="+rows[0].id)
            }
            else
            {
                setIdOk(false)
            }
            
        }
        
      })},[id,idOk])

if (!idOk) return (<>رقم السند غير صحيح</>)
return(<EditReceipts   receiptVoucher={receiptVoucher}  /> )
}
export default ReceiptsInfo
