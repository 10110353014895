import { Dropdown, DropdownButton } from "react-bootstrap"
import { Link } from "react-router-dom"
import DeleteReceiptVocher from "../delete/DeleteReceiptVocher"

function ReceiptVocherListActions(props) {
    const {receiptVoucher,removeTableItem}=props
    const editLink='/receipts/'+(receiptVoucher.id)?receiptVoucher.id:0
    //const receiptsLink = '/receipts/?tab=add&&insurance='+insurance.id+'&owner='+insurance.insurances_owner_id // tab=add&&car=8
    return (
    <DropdownButton  title="المزيد">


     <Dropdown.Item ><DeleteReceiptVocher receiptVoucher={receiptVoucher} removeTableItem={removeTableItem} /></Dropdown.Item>
    {/*<Dropdown.Item as={Link} to={receiptsLink}> سند قبض </Dropdown.Item> */}
    <Dropdown.Item as={Link} to={editLink} > تعديل </Dropdown.Item>
  </DropdownButton>)
  }
export default ReceiptVocherListActions