import axios from "axios"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import EditCar from "../componant/EditCar"
import path from "../path"
function CarInfo()
{
    //const path='https://dev.ps-on-line.com/api/'
    const { id } = useParams()
    const [car,setCar]=useState({})
  
        const [idOk,setIdOk]=useState(!isNaN(id) && id>0)
        useEffect(()=>{
            //const auth=localStorage.getItem('auth')
            if(!idOk) return
            axios.post(path+'insurances.php?do=one_car',{'id':Number(id)})
          .then(res=>{
            console.log(res);
            if(res.data.result===1)
            {
                
                const cars=res.data.data
                if (cars.length>0)
                {
                    setCar(cars[0])
                    setIdOk(true)
                    //alert("car id="+car.id)
                }
                else
                {
                    setIdOk(false)
                }
                
            }
            
          })},[id,idOk])

        if (idOk)
        {
            return(
            <><EditCar car={car}/></>)
        }
        else
        return(
            <>رقم  السجل غير صحيح</>)
        
    
    

    
    
}
export default CarInfo