import axios from 'axios';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import path from "../../path"

function DeleteInsurance({insurance,removeTableItem}) {
  //const path='https://dev.ps-on-line.com/api/'
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleDelect = () =>{
    setShow(false)
    
    axios.post(path+'delete.php?do=insurance',{'id':insurance.id})
          .then(res => {
            console.log("delete res="+res);

            if(res.data.result===1)
            {

                //alert("deleted ok "+car.id)
                //alert("delete res="+res.data.data)
                removeTableItem(insurance)
                toast.success("تم الحذف بنجاج !", {
                  position: toast.POSITION.TOP_CENTER
                });
            }
            
    
          })
          .catch((error) => {
            console.log('error');
            console.log(error);
          })
          
          
    
      }
  
  return (
    <>
      <div onClick={handleShow}>
        حذف
      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>حذف السجل</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          هل انت متاكد من حذف السجل الخاص باالتامين{insurance.id} لا يمنكن ارجاع البيانات
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            الغاء
          </Button>
          <Button variant="primary" onClick={handleDelect}>
            حذف
            </Button>
        </Modal.Footer>
      </Modal>
      
    </>
  );
}

export default DeleteInsurance