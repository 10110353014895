import { useEffect, useRef, useState } from "react"
import { Form } from "react-bootstrap"
import FormTypeahead from "../FormTypeahead"
import axios from "axios"
import AddCarOwner from "../AddCarOwner"
import path from "../../path"

function SelectInsuranceOwner({updatePost,lable,defVal,name})
{
    //const path='https://dev.ps-on-line.com/api/'
    const usedName = (name)?name:'owner'
    const [ownersList,setOwnersList] = useState([])
    const ref=useRef()
    const autoSelectOwner= (data,newSelectedval)=>{
        console.log(newSelectedval);
        setOwnersList(data)
        updatePost(usedName,newSelectedval.id)
        ref.current?.updateSelected(newSelectedval)
      }
    

    useEffect(() => {
        axios.get(path+'insurances.php?do=owners')
              .then(res => {
                //console.log("addcar res="+res);
      
                if(res.data.result===1)  {

                    setOwnersList(res.data.data)
                    console.log('selectinsuranceOwner defVal='+defVal)
                    if(defVal)
                    {
                      console.log('selectinsuranceOwner defVal='+defVal)
                      const data=res.data.data

                      for(var i = 0; i < data.length; i++)
                      {
                        if(data[i].id === defVal)
                        {
                          //alert(data[i].id) 
                          //console.log("delVal number = "+data[i].number);
                          ref.current?.updateSelected(data[i])
                          //autoSelectOwner(data,data[i])
                        }
                      }
                    }
                }
                else {
                  console.log("not ok SelectInsuranceOwner")
                }
                
        
              })
              .catch((error) => {
                console.log('error');
                console.log(error);
                //alert("error")
              })
      },[defVal])
    return(
            <>
            <Form.Group className="mb-3" >
            <Form.Label>{lable}</Form.Label>
            <FormTypeahead 
            ref={ref}
            data={ownersList}  
            updatePost={updatePost}
            name={usedName}
            labelKey="name"
            />
            <AddCarOwner autoSelectOwner={autoSelectOwner}/>
          </Form.Group>

          
          </>
        
        
    )
}
export default SelectInsuranceOwner