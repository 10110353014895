import axios from "axios"
import { useState } from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import { toast } from "react-toastify"
import SelectInsurance from "./fourmcomponants/SelectInsurance"
import SelectInsuranceOwner from "./fourmcomponants/SelectInsuranceOwner"
import { useSearchParams } from "react-router-dom"
import path from "../path"

function ReceiptVoucher({updateTable})
{
  const [searchParams] = useSearchParams();
  const selectedInsuranceId=searchParams.get('insurance')
  const selectedOwnerId=searchParams.get('owner')
  //const path='https://dev.ps-on-line.com/api/'
  var curr = new Date();
  var date = curr.toISOString().substring(0,10);
  const emptyForm ={
    insurance_number:'',
    voucher_date:date,
    amount:0,
    that_for:'1',
    who_pay:'',
    note:''
  }
  //const ownerRef = useRef();
  //const [selectOwnerData,setSelectOwnerData]=useState([])
  const [postData,setPostData] = useState(emptyForm)
  const handelOnChange = (e) => {
    const value = e.target.value
    const name = e.target.name
    setPostData({...postData, [name]: value})
    console.log("postData");
    console.log(postData)
}

const updatePost= (postname,postval)=>
    {
      setPostData({...postData, [postname]: postval})
      //console.log(postData)
    }

const handelSubmit = e => {
  e.preventDefault()
  console.log(postData);
  /*
  if(postData.number==='' ||
  postData.car_number==='' ||
  postData.start_date==='' ||
  postData.end_date==='' ||
  postData.next_date==='' ||
  postData.value==='' ||
  postData.real_value==='' ||
  postData.owner==='' )
  {
    alert("u must add all feilds")
    return
  }
  */
  console.log("postData to send");
  console.log(postData)
  axios.post(path+'add.php?do=receipt_voucher',postData)
    .then(res => {
      //console.log("AddInsurances res="+res);

      if(res.data.result===1)
      {  
        //setTableData(res.data.data) 
        toast.success("تمت الاضافة بنجاج!",{
          position: toast.POSITION.TOP_CENTER
        });
        setPostData(emptyForm)
        updateTable()
        document.getElementById("uncontrolled-tab-receipts-tab-table").click()
        
      }
      else
          {
            console.log("not ok")
          }
      

    })
    .catch((error) => {
      console.log('error');
      console.log(error);
    })
    
    

}

  return(<div className="container">
    <Form onSubmit={handelSubmit}>
      <Row className="mb-3">
      <Form.Group as={Col} className="mb-3" >
              <Form.Label>تاريخ السند</Form.Label>
              <Form.Control 
                name="voucher_date"
                value={postData.start_date}
                onChange={handelOnChange}
                type="date" 
                placeholder="اختر التاريخ" />
      </Form.Group>
      <Form.Group  as={Col} className="mb-3">
          <Form.Label>المبلغ نقدي</Form.Label>
          <Form.Control 
            type="number" 
            name ="amount"
            value={postData.amount} 
            onChange={handelOnChange}
            placeholder="ادخل المبلغ" />
        </Form.Group>
        <Col>
            <SelectInsurance updatePost={updatePost} defVal={selectedInsuranceId} />
        </Col>
        <Col>
          <SelectInsuranceOwner updatePost={updatePost} lable="احتر اسم الدافع" name="who_pay" defVal={selectedOwnerId} />
          {/* <Form.Group className="mb-3" >
            <Form.Label>اختر الدافع</Form.Label>
            <FormTypeahead
            data={ownersList}  
            updatePost={updatePost}
            name="who_pay" 
            labelKey="name"
            selectedval={selectOwnerData}
            ref={ownerRef}/>
            <AddCarOwner autoSelectOwner={autoSelectOwner}/>
          </Form.Group> */}
        </Col>
        
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} className="mb-3">
          <Form.Label>ملاحظات</Form.Label>
          <Form.Control 
            name="note"
            value={postData.note}
            onChange={handelOnChange} 
            type="text" 
            placeholder="ملاحظات ..." />
        </Form.Group>
      </Row>
      <Button variant="primary" type="submit">
        Submit
      </Button>
    </Form>
    </div>
    )
}
export default ReceiptVoucher
