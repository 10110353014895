import axios from "axios";

function Logout()
{
    localStorage.clear();
    axios.defaults.headers.common['Authorization']=''
    window.location.replace('/')
    return (<>
        <h1>مع السلامة</h1>
        <redirect to="/somewhere/else" />
    </>)
}
export default Logout