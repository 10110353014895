import InsurancesTable from "../componant/InsurancesTable"
import AddInsurances from "../componant/AddInsurances";
import { Tab, Tabs } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { useRef } from "react";
function Insurances(loginOk) {    

  
  const [searchParams] = useSearchParams();
  const tab=(searchParams.get('tab'))?searchParams.get('tab'):'table'

  const ref = useRef();
  const updateTable=()=>{
    ref.current?.updateTable()
  }


 
    if (!loginOk) return (<></>)
    return(
        <>
        
        <div className="container">
        </div>
        <Tabs defaultActiveKey={tab}
      id="uncontrolled-tab-cars"
      className="mb-3">
        <Tab eventKey="table" title="قائمة التامينات">
            <InsurancesTable ref={ref}  loginOk={loginOk} />
        </Tab>
        <Tab eventKey="add" title="اضافة تامين">
        <div className="container">
            <AddInsurances updateTable={updateTable} />
        </div>
        </Tab>
        
    </Tabs>  
        </>
    )
}
export default Insurances