import { useEffect, useRef, useState } from "react"
import { Form } from "react-bootstrap"
import FormTypeahead from "../FormTypeahead"
import axios from "axios"
import path from "../../path"

function SelectInsurance({updatePost,defVal})
{
    //const path='https://dev.ps-on-line.com/api/'
    const [insuranceList,setInsuranceList]= useState([])
    const ref=useRef()


    useEffect(() => {
        //const d=new Date()
        //console.log(d.getVarDate);
        axios.get(path+'insurances.php?do=insurance_owners')
              .then(res => {
                //console.log("addcar res="+res);
      
                if(res.data.result===1)
                {
      
                  setInsuranceList(res.data.data)
                  if(defVal)
                  {
                    console.log('defVal='+defVal)
                    const data=res.data.data
                    for(var i = 0; i < data.length; i++)
                    {
                      if(data[i].id === defVal)
                      {
                        //alert(data[i].id) 
                        console.log("delVal number = "+data[i].number);
                        ref.current?.updateSelected(data[i])
                        //autoSelectOwner(data,data[i])
                      }
                    }
                  }
                    
                    
                }
                else
                {
                  console.log("not ok")
                }
                
        
              })
              .catch((error) => {
                console.log('error');
                console.log(error);
                //alert("error")
              })
      },[defVal])
    return(
        <Form.Group className="mb-3" >
            <Form.Label>اختر رقم التامين</Form.Label>
            <FormTypeahead
            data={insuranceList}  
            updatePost={updatePost}
            name="insurance_number" 
            labelKey="number"
            // selectedval={selectOwnerData}
            ref={ref}
            />
            
          </Form.Group>
    )
}
export default SelectInsurance