import {  forwardRef, useImperativeHandle, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";

const FormTypeahead = forwardRef(({data,updatePost,name,labelKey}, ref) =>
{
    const [singleSelections, setSingleSelections] = useState([]);
    const [invalid,setInvalid]=useState()
    const handelOnChangeType = (selected,name) => {
        setSingleSelections(selected)
        //const name = e.target.name
        if(selected.length>0)
        {
          const value = selected[0].id;
          //console.log(selected[0].id);
          //setPostData({...postData, [name]: value})
          updatePost(name,value)
          setInvalid(false)
        }
        else
        {
          //setPostData({...postData, [name]: 0})
          updatePost(name,0)
          setInvalid(true)
        }
        
        //console.log(postData)
        
        
      }


      useImperativeHandle(ref, () => ({
        loga() {
          console.log("child function");
        },
        updateSelected(newSelectedval)
        {
          setSingleSelections([newSelectedval])
          updatePost(name,newSelectedval.id)
        }
      }))
    return (
    <Typeahead
      name={name}
      isInvalid={invalid}
      id="basic-typeahead-single"
      labelKey={labelKey}
      onChange={(selected)=>handelOnChangeType(selected,name)}
      options={data}
      placeholder="اختر"
      selected={singleSelections}
    />
    )
})
export default FormTypeahead