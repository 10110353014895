import axios from "axios"
import { useEffect, useState } from "react"
import { Button, Col, Form, Row } from "react-bootstrap"
import { toast } from "react-toastify"

import SelectInsuranceOwner from "./fourmcomponants/SelectInsuranceOwner"
import SelectCarNamber from "./fourmcomponants/SelectCarNamber"
import path from "../path"


function EditInsurance({insurance})
{
  //const path='https://dev.ps-on-line.com/api/'
    //const [ownersList,setOwnersList] = useState([])
    const emptyForm ={
      id:'',
      number:'',
      car_number:'',
      start_date:'',
      end_date:'',
      next_date:'',
      value:'',
      real_value:'',
      owner:'',
      note:''
    }
    const [postData,setPostData] = useState(emptyForm)
    const updatePost= (postName,postVal)=>
    {
      setPostData({...postData, [postName]: postVal})

    }
    const handelOnChange = (e) => {
      const value = e.target.value
      const name = e.target.name
      setPostData({...postData, [name]: value})
      console.log(postData)
  }

  const handelSubmit = e => {
    e.preventDefault()
    console.log(postData);
    if(postData.number==='' ||
  
    postData.car_number==='' ||
    postData.start_date==='' ||
    postData.end_date==='' ||
    postData.next_date==='' ||
    postData.value==='' ||
    postData.real_value==='' ||
    postData.owner==='' )
    {
      alert("u must add all feilds")
      return
    }
    axios.post(path+'edit.php?do=insurance',postData)
      .then(res => {
        console.log("AddInsurances res="+res);
  
        if(res.data.result===1)
        {
            //alert("تمت الاضافة")
            //setTableData(res.data.data) ;
            toast.success("تم الالتعديل بنجاج !", {
              position: toast.POSITION.TOP_CENTER
            });
            //setPostData(emptyForm)
        }
        
  
      })
      .catch((error) => {
        console.log('error');
        console.log(error);
      })
      
      
  
  }
  useEffect(()=>{
            setPostData({
            id:insurance.id,
            number:insurance.number,
            car_number:insurance.car_id,
            start_date:insurance.insurances_date,
            end_date:insurance.insurances_end_date,
            next_date:insurance.next_batch_date,
            value:insurance.agreed_value,
            real_value:insurance.actual_value,
            owner:insurance.insurances_owner_id,
            note:insurance.note
    });
  },[insurance])


    // useEffect(() => {
    //     setPostData({
    //         id:insurance.id,
    //         number:insurance.number,
    //         car_number:insurance.car_id,
    //         start_date:insurance.insurances_date,
    //         end_date:insurance.insurances_end_date,
    //         next_date:insurance.next_batch_date,
    //         value:insurance.agreed_value,
    //         real_value:insurance.actual_value,
    //         owner:insurance.insurances_owner_id,
    //         note:insurance.note


    // })
    
    //   axios.get(path+'insurances.php?do=cars_numbers')
    //         .then(res => {
    //           //console.log("addcar res="+res);
  
    //           if(res.data.result===1)
    //           {
  
    //               setCarsList(res.data.data)
    //               setOwnersList(res.data.data2)
    //               setPostData({
    //                 id:insurance.id,
    //                 number:insurance.number,
    //                 car_number:insurance.car_id,
    //                 start_date:insurance.insurances_date,
    //                 end_date:insurance.insurances_end_date,
    //                 next_date:insurance.next_batch_date,
    //                 value:insurance.agreed_value,
    //                 real_value:insurance.actual_value,
    //                 owner:insurance.insurances_owner_id,
    //                 note:insurance.note
        
        
    //         })
    //           }
              
      
    //         })
    //         .catch((error) => {
    //           console.log('error');
    //           console.log(error);
    //         })
    // },[insurance.number,insurance.insurances_date,insurance.insurances_end_date,insurance.next_batch_date, insurance.actual_value, insurance.agreed_value, insurance.car_id, insurance.insurances_owner_id, insurance.note,insurance.id])
   
    
    return(<>EditInsurance {insurance.number}
     <Form onSubmit={handelSubmit} >
      <Row className="mb-3">
        <Form.Group  as={Col} className="mb-3">
          <Form.Label>رقم التامين</Form.Label>
          <Form.Control 
            type="text" 
            name ="number"
            value={postData.number} 
            onChange={handelOnChange}
            placeholder="ادخل رقم التامين" />
        </Form.Group>
        <Col>
          <SelectCarNamber  updatePost={updatePost} defVal={insurance.car_id} />
        </Col>
       
        {/* <Form.Group as={Col} className="mb-3" >
          <Form.Label>اختر رقم السارة</Form.Label>
          <Form.Select 
            name="car_number"
            value={postData.car_number} 
            onChange={handelOnChange}
            aria-label="Default select example" 
            className="selectpicker" >
            <option>اختر</option>
            {carsList.map((car) => (
              <option value={car.id}>{car.number}</option>
            ))}
          </Form.Select>
          <Link to="/cars" as={Col} className="btn btn-primary">اذهب لقائزه اضافة السيارات</Link>
        </Form.Group> */}
        {/* <Form.Group as={Col} className="mb-3" >
          <Form.Label>اختر صاحب التامين</Form.Label>
          <Form.Select 
            aria-describedby="basic-addon1"
            name="owner"
            value={postData.owner} 
            onChange={handelOnChange}
            className="selectpicker">
            <option>اختر</option>
            {ownersList.map((owner) => (
              <option value={owner.id}>{owner.name}</option>
            ))}
          </Form.Select>
          <AddCarOwner />
        </Form.Group> */}
        <Col>
        <SelectInsuranceOwner updatePost={updatePost} lable="اختر صاحب التامين" defVal={insurance.insurances_owner_id} />

        </Col>
        

      </Row>  
      <Row className="mb-3">
          
            <Form.Group as={Col} className="mb-3" >
              <Form.Label>تاريخ التامين</Form.Label>
              <Form.Control 
                name="start_date"
                value={postData.start_date}
                onChange={handelOnChange}
                type="date" 
                placeholder="اختر التاريخ" />
            </Form.Group>
          
          
            <Form.Group as={Col} className="mb-3" >
              <Form.Label>تاريخ انتهاء التامين</Form.Label>
              <Form.Control 
                name="end_date" 
                value={postData.end_date}
                onChange={handelOnChange}
                type="date" 
                placeholder="اختر التاريخ" />
            </Form.Group>
            <Form.Group as={Col} className="mb-3" >
              <Form.Label>تاريخ الدفعة القادمة</Form.Label>
              <Form.Control 
                name="next_date" 
                value={postData.next_date}
                onChange={handelOnChange}
                type="date" 
                placeholder="اختر التاريخ" />
            </Form.Group>
          
      </Row>  
      <Row className="mb-3">

            <Form.Group as={Col} className="mb-3">
              <Form.Label>القيمة المتفق عليها</Form.Label>
              <Form.Control 
                name="value"
                value={postData.value}
                onChange={handelOnChange} 
                type="number" 
                placeholder="ادخل رقم التامين" />
            </Form.Group>

            <Form.Group as={Col} className="mb-3">
              <Form.Label>القيمة الفعلية</Form.Label>
              <Form.Control 
                name="real_value" 
                type="number" 
                value={postData.real_value}
                onChange={handelOnChange}
                placeholder="ادخل رقم التامين" />
            </Form.Group>

      
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} className="mb-3">
          <Form.Label>ملاحظات</Form.Label>
          <Form.Control 
            name="note"
            value={postData.note}
            onChange={handelOnChange} 
            type="text" 
            placeholder="ملاحظات ..." />
        </Form.Group>
      </Row>
      <Button variant="primary" type="submit">
        تعديل
      </Button>
      
      
      
    </Form>
    
    </>)

}
export default EditInsurance


