

import { useState } from "react";
import SelectInsurance from "../componant/fourmcomponants/SelectInsurance";
import { Col, Form } from "react-bootstrap";


function UserAccont()

{
    const [postData,setPostData]=useState({'insurance_number':0})

    const updatePost=(postname,postval)=>
    {
        console.log(postname+" :postval="+postval)
        setPostData({...postData, [postname]: postval})
    }
    return (<div>
        <Form>
            <Col>
                <SelectInsurance updatePost={updatePost} />
            </Col>
        </Form>

            zzzz
    </div>)
}
export default UserAccont