//import axios from "axios";
//import { useState,useEffect, useCallback } from "react";

import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import CarListAction from "./listActine/CarListAction";
import axios from "axios";
import path from "../path";
import Loading from "./Loading";
const CarsTable=forwardRef(({loginOk} , ref) => {
    const [carsList,setCarsList]=useState ([])
    const [loading,setLoading] = useState(false)
    useImperativeHandle(ref, () => ({

        updateTable()
        {
            updateData()
        }
      }))

    function removeTableItem(car){
        const index = carsList.indexOf(car);

        //const x = myArray.splice(index, 1);
        //alert("index="+index)
        setCarsList(
            [
                ...carsList.slice(0, index),
                ...carsList.slice(index + 1)
              ]
        )

    }
   
    useEffect(()=>{
        //const auth=localStorage.getItem('auth')
        if (loginOk) updateData()
        
    
    },[loginOk])
    const updateData=()=>{
        setLoading(true)
        axios.get(path+'insurances.php?do=cars')
        .then(res=>{
            console.log(res);
            if(res.data.result===1)
            {
                setCarsList(res.data.data)

            }
        setLoading(false)
      
        })
        .catch(
            setLoading(false)
        )
    }
    
    //const tableNew=useCallback(carsList)

 
    if(loading) return <Loading />
    return(<div className="container">
    <h1>قائمة السيارات</h1>
    <table className="table table-bordered border-primary">
        <thead>
            <tr>
                <th>#</th>
                <th>رقم السيارة</th>
                <th>مالك السارة</th>
                <th>نوع السارة</th>
                <th>رقم التامين</th>
                <th>ملاحظات</th>
                <th>خيارات</th>
            </tr>
        </thead>
        <tbody>
            {carsList.map((car)=>(
                <tr key={car.id} >
                    <td>{car.id}</td>
                    <td>{car.number}</td>
                    <td>{car.name}</td>
                    <td>{car.manufacture} {car.modle} </td>
                    <td>{car.insurances_namber}</td>
                    <td>{car.note}</td>
                    <td><CarListAction car={car} removeTableItem={removeTableItem}/></td>
                    
                </tr>))}
        </tbody>
    </table>
    </div>)
    

            })
export default CarsTable;