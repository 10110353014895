import axios from "axios"
import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import EditInsurance from "../componant/EditInsurance"
import path from "../path"

function InsuranceInfo()
{
    //const path='https://dev.ps-on-line.com/api/'
    const { id } = useParams()
    const [insurance,setIsurance]=useState({})
    const [idOk,setIdOk]=useState(!isNaN(id) && id>0)
    useEffect(()=>{

        if(!idOk) return
        axios.post(path+'insurances.php?do=one_insurance',{'id':Number(id)})
          .then(res=>{
            console.log(res);
            if(res.data.result===1)
            {
                
                const insurances=res.data.data
                if (insurances.length>0)
                {
                    setIsurance(insurances[0])
                    setIdOk(true)
                    //alert("car id="+car.id)
                }
                else
                {
                    setIdOk(false)
                }
                
            }
            
          })},[id,idOk])

    if (!idOk) return (<>رقم التامين غير صحيح</>)
    return(<EditInsurance   insurance={insurance}  /> )
}
export default InsuranceInfo