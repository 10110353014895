import axios from "axios"
import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import path from "../path"
import ReceiptVocherListActions from "./listActine/ReceiptVocherListActions"
//forwardRef(({data,updatePost,name,labelKey}, ref)
//const path='https://dev.ps-on-line.com/api/'
const ReceiptVoucherTable=forwardRef((props, ref) => {
    const [receiptVoucherList,setReceiptVoucherList]=useState([])


    function removeTableItem(receiptVoucher){
        const index = receiptVoucherList.indexOf(receiptVoucher);

        //const x = myArray.splice(index, 1);
        //alert("index="+index)
        setReceiptVoucherList(
            [
                ...receiptVoucherList.slice(0, index),
                ...receiptVoucherList.slice(index + 1)
              ]
        )
    }
    const readData=()=>{
        //const auth=localStorage.getItem('auth')
        //setLoading(true)
        axios.get(path+'insurances.php?do=ReceiptVouchers')
      .then(res=>{
        console.log(res);
        if(res.data.result===1)
        {
            setReceiptVoucherList(res.data.data)
        }
        //setLoading(false)
    })
    }
    useEffect(()=>{
        readData()
      
      
    },[])
    useImperativeHandle(ref, () => ({
        loga() {
          console.log("child function");
        },
        updateTable()
        {
            readData()
        }
      }))
    return(

<div className="container">
<h1>سندات قبض</h1>
<table className="table table-bordered border-primary">
    <thead>
        <tr>
            <th>ID</th>
            <th>رقم التامين</th>
            <th>اسم المؤمن</th>
            <th>اسم الدافع</th>
            <th>الملغ</th>
            <th>ملاحظات</th>
            <th>التاريخ</th>
            <th>المزيد</th>
        </tr>
    </thead>
    <tbody>
        {receiptVoucherList.map((receiptVoucher)=>(
                <tr key={receiptVoucher.id} >
                    <td>{receiptVoucher.id}</td>
                    <td>{receiptVoucher.number}</td>
                    <td>{receiptVoucher.owner_name}</td>
                    <td>{receiptVoucher.who_pay}</td>
                    <td>{receiptVoucher.amont}</td>
                    <td>{receiptVoucher.note}</td>
                    <td>{receiptVoucher.date}</td>
                    <td><ReceiptVocherListActions receiptVoucher={receiptVoucher} removeTableItem={removeTableItem} /> </td>
                   
                    
                </tr>))}
    </tbody>
</table>
</div>)
})

export default ReceiptVoucherTable