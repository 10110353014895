import axios from 'axios';
//import { Alert } from 'bootstrap';
import {  useState } from 'react';
import { Row,Col } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { toast } from 'react-toastify';

import SelectCarNamber from './fourmcomponants/SelectCarNamber';
import SelectInsuranceOwner from './fourmcomponants/SelectInsuranceOwner';
import path from "../path"
import { useSearchParams } from 'react-router-dom';

function AddInsurances({updateTable}) {
  //const path='https://dev.ps-on-line.com/api/'
  const updatePost= (postName,postVal)=>
  {
    setPostData({...postData, [postName]: postVal})

  }
  const emptyForm ={
    number:'',
    car_number:'',
    start_date:'',
    end_date:'',
    next_date:'',
    value:'',
    real_value:'',
    owner:'',
    note:''
  }


  const [postData,setPostData] = useState(emptyForm)
  const handelOnChange = (e) => {
    const value = e.target.value
    const name = e.target.name
    setPostData({...postData, [name]: value})
    console.log(postData)
}

const handelSubmit = e => {
  e.preventDefault()
  console.log(postData);
  if(postData.number==='' ||
  postData.car_number==='' ||
  postData.start_date==='' ||
  postData.end_date==='' ||
  postData.next_date==='' ||
  postData.value==='' ||
  postData.real_value==='' ||
  postData.owner==='' )
  {
    alert("u must add all feilds")
    console.log(postData);
    return
  }
  console.log(postData);
  axios.post(path+'add.php?do=insurances',postData)
    .then(res => {
      console.log("AddInsurances res="+res);

      if(res.data.result===1)
      {  
        //setTableData(res.data.data) 
        updateTable()
        toast.success("تمت الاضافة بنجاج!",{
          position: toast.POSITION.TOP_CENTER
        });
        setPostData(emptyForm)
        document.getElementById("uncontrolled-tab-cars-tab-table").click()
        
      }
      else 
      {
        console.log(res.data)
      }

      

    })
    .catch((error) => {
      console.log('error');
      console.log(error);
    })
    
    

}
const [searchParams] = useSearchParams();

  const selectedCarId=searchParams.get('car')
  // useEffect(() => {
  //   axios.get(path+'insurances.php?do=cars_numbers')
  //         .then(res => {
  //           //console.log("addcar res="+res);

  //           if(res.data.result===1)
  //           {

  //               //setCarsList(res.data.data)
  //               //setOwnersList(res.data.data2)
                
  //                 const car_id=(searchParams.get('car'))?searchParams.get('car'):''
  //                 setPostData({
  //                   number:'',
  //                   car_number:car_id,
  //                   start_date:'',
  //                   end_date:'',
  //                   next_date:'',
  //                   value:'',
  //                   real_value:'',
  //                   owner:'',
  //                   note:''})
                
                
  //           }
            
    
  //         })
  //         .catch((error) => {
  //           console.log('error');
  //           console.log(error);
  //         })
  // },[searchParams])

  return (
    <Form onSubmit={handelSubmit} >
      <Row className="mb-3">
        <Form.Group  as={Col} className="mb-3">
          <Form.Label>رقم التامين</Form.Label>
          <Form.Control 
            type="text" 
            name ="number"
            value={postData.number} 
            onChange={handelOnChange}
            placeholder="ادخل رقم التامين" />
        </Form.Group>
      
        <Col>
          <SelectCarNamber updatePost={updatePost} defVal={selectedCarId}/>

        </Col>
          
        <Col>
        <SelectInsuranceOwner updatePost={updatePost} lable="احتر صاحب التامين"/>

        </Col>


      </Row>  
      <Row className="mb-3">
          
            <Form.Group as={Col} className="mb-3" >
              <Form.Label>تاريخ التامين</Form.Label>
              <Form.Control 
                name="start_date"
                value={postData.start_date}
                onChange={handelOnChange}
                type="date" 
                placeholder="اختر التاريخ" />
            </Form.Group>
          
          
            <Form.Group as={Col} className="mb-3" >
              <Form.Label>تاريخ انتهاء التامين</Form.Label>
              <Form.Control 
                name="end_date" 
                value={postData.end_date}
                onChange={handelOnChange}
                type="date" 
                placeholder="اختر التاريخ" />
            </Form.Group>
            <Form.Group as={Col} className="mb-3" >
              <Form.Label>تاريخ الدفعة القادمة</Form.Label>
              <Form.Control 
                name="next_date" 
                value={postData.next_date}
                onChange={handelOnChange}
                type="date" 
                placeholder="اختر التاريخ" />
            </Form.Group>
          
      </Row>  
      <Row className="mb-3">

            <Form.Group as={Col} className="mb-3">
              <Form.Label>القيمة المتفق عليها</Form.Label>
              <Form.Control 
                name="value"
                value={postData.value}
                onChange={handelOnChange} 
                type="number" 
                placeholder="ادخل رقم التامين" />
            </Form.Group>

            <Form.Group as={Col} className="mb-3">
              <Form.Label>القيمة الفعلية</Form.Label>
              <Form.Control 
                name="real_value" 
                type="number" 
                value={postData.real_value}
                onChange={handelOnChange}
                placeholder="ادخل رقم التامين" />
            </Form.Group>

      
      </Row>
      <Row className="mb-3">
        <Form.Group as={Col} className="mb-3">
          <Form.Label>ملاحظات</Form.Label>
          <Form.Control 
            
            name="note"
            value={postData.note}
            onChange={handelOnChange} 
            type="text" 
            placeholder="ملاحظات ..." />
        </Form.Group>
      </Row>
      <Button variant="primary" type="submit">
        اضافة تامين
      </Button>
      
      
      
    </Form>
  );
}
export default AddInsurances;