import { useEffect, useRef, useState } from "react"
import { Form } from "react-bootstrap"
import FormTypeahead from "../FormTypeahead"
import axios from "axios"
import AddCarModel from "../AddCarModel"
import path from "../../path"

function SelectCarModel({updatePost,defVal})
{
    //const path='https://dev.ps-on-line.com/api/'
    const [selectModelData,setSelectModelData]=useState([])
    const modelRef  = useRef();
    function autoSelectModel (data,newSelectedval) 
    {
        setSelectModelData(data,newSelectedval)
        //setPostData({...postData, car_model: id })
        modelRef.current?.updateSelected(newSelectedval)
        updatePost("car_model",newSelectedval.id)
        //alert(id)
    }

    // const updatePost= (_,postval)=>
    // {
    //     onChangeValHandel('insurance_number',postval)
    // }
    useEffect(() => {
        //const d=new Date()
        //console.log(d.getVarDate);
        axios.get(path+'insurances.php?do=models')
              .then(res => {
                //console.log("addcar res="+res);
      
                if(res.data.result===1)  {

                    setSelectModelData(res.data.data)
                    if(defVal)
                    {
                      console.log('selectCarModel defVal='+defVal)
                      const data=res.data.data

                      for(var i = 0; i < data.length; i++)
                      {
                        if(data[i].id === defVal)
                        {
                          //alert(data[i].id) 
                          //console.log("delVal number = "+data[i].number);
                          modelRef.current?.updateSelected(data[i])
                          //autoSelectOwner(data,data[i])
                        }
                      }
                    }
                }
                else {
                  console.log("not ok SelectCarNumber")
                }
                
        
              })
              .catch((error) => {
                console.log('error');
                console.log(error);
                
              })
      },[defVal])
    return(
        <>
          {/* <Form.Group className="mb-3" >
            <Form.Label>اختر مديل السيارة</Form.Label>
            <FormTypeahead 
                data={modelList}  
                updatePost={updatePost}
                name="car_number"
                labelKey="number"
            />
            <Link to="/cars" as={Col} className="btn btn-primary">اذهب لقائزه اضافة السيارات</Link>
          </Form.Group> */}

          <Form.Group className="mb-3" >
            <Form.Label>اختر مديل السيارة</Form.Label>
            <FormTypeahead
            data={selectModelData}  
            updatePost={updatePost}
            name="car_model" 
            labelKey="model"
            ref={modelRef}/>
            <AddCarModel autoSelectModel={autoSelectModel}  />
          </Form.Group>


          </>
        
        
    )
}
export default SelectCarModel