
import { useRef } from "react";
import AddCar from "../componant/AddCar";
import CarsTable from "../componant/CarsTable";
import { Tab, Tabs } from "react-bootstrap";
function Cars({loginOk}) {
    const ref=useRef()
    const updateTable=()=>
    {
      ref?.current?.updateTable()
    }
    //const [change,setChange]=useState({val:0});
    //const path='https://dev.ps-on-line.com/api/'
    
    
    //alert("change="+change.val);
    // if (loading) return(<div className="d-flex justify-content-center">
    // <div className="spinner-border" role="status">
    //   <span className="visually-hidden">Loading...</span>
    // </div>
    //  </div>)
    return(<>
    <Tabs defaultActiveKey="table"
      id="uncontrolled-tab-cars" 
      className="mb-3">
        <Tab eventKey="table" title="قائمة السيارات">
          <CarsTable loginOk={loginOk} ref={ref} />
        </Tab>
        <Tab eventKey="add" id="tabadd" title="اضافة سيارة">
          <div className="container"><AddCar updateTable={updateTable} /></div>
        </Tab>
        
    </Tabs>    
    </>)
    
}

export default Cars;