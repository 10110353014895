
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Link } from 'react-router-dom';
import DeleteCar from '../delete/DeleteCar';

function CarListAction({car,removeTableItem}) {
  
  const l='/cars/'+car.id
  const linkAdd='/insurances/?tab=add&&car='+car.id
  console.log(car.inserance_id);
  return (<>
    <DropdownButton car={car}  title="المزيد">
      {(car.inserance_id)?<Dropdown.Item as={Link} to={l} >تعديل</Dropdown.Item>:<>
      <Dropdown.Item as={Link} to={linkAdd}>انشاء تامين للسيارة</Dropdown.Item>
      <Dropdown.Item as={Link} to={l} >تعديل</Dropdown.Item>
      <Dropdown.Item ><DeleteCar car={car} removeTableItem={removeTableItem} /></Dropdown.Item></>}
    </DropdownButton>
    
    </>
  );
}

export default CarListAction;